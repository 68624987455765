@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  margin-top: 3%;
  scroll-behavior: smooth;
}

h5 {
  font-size: 16px;
  font-family: cedarville cursive;
}

.mobileIcon {
  display: none;
  z-index: 2;
}

#mycheckbox {
  display: none;
}

nav{
  /* position: absolute;
  top: -320px;
  height: 305px; */
  visibility: hidden;
  height: 0px;
  width: 100%;
  background-color: rgb(245, 243, 243);
  font-family: "Press Start 2P";
}

nav ul {
  width: 100%;
  list-style-type: none;
  padding: 0;
  position: relative;
  top: -320px;
}

nav ul li{
  width: 100%;
  line-height: 100px;
  border-bottom: 1px solid;
}

nav ul li a{
  font-size: 14px;
}


.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  /* grid-template-columns: repeat(2, 1fr); */
  grid-auto-rows: 300px auto;
  grid-gap: 10px;
  text-align: center;
  width: 75%;
  margin-left: 20%;
  grid-gap: 10px;
  grid-auto-flow: dense;

}

.gallery-container{
  overflow: hidden;
}

.content {
  overflow: hidden;
  width: 100%;
  height: 100%;
}


.fullNav {
  width: 8%;
  list-style-type: none;
  text-align: right;
  display: inline-block;
  padding-top: 0.1%;
  margin-left: 3%;
  margin-top: 0px;
  position: fixed;
  left: 1%;
  font-family: "Press Start 2P";
  font-size: 12px;
  font-style: italic;
  padding: 0;
  width: 15%
}

a {
  text-decoration: none;
  color: black;
  display: block;
  font-style: normal;
}

.fullNav li {
  line-height: 35px;
  width: 100%;
  display: block;
}

img {
  width: 95%;
  padding-top: 1%;  
  padding-left: 1%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

h1 {
  display: block;
  float: right;
  font-family: "Press Start 2P";
  font-size: 24px;
  width: 100%;
}

span {
  color: rgb(114, 108, 108);
}

svg{
  height: 75%;
  width: 75%;
}

a{
  display: inline-block;
}

a svg {
  height: 17px;
  width: 17px;
  margin-right: 10px;
}

a img {
  height: 18px;
  width: 19px;
}

.react-player {
  display: inline-block;
  padding-left: 1%;
  padding-top: 1%;
  height: 90%;
}

.w-2{
  grid-column: span 2;
}

.w-4{
  grid-row: span 2;
  
}

.w-5{
  grid-row: span 2;
}

#arrow {
    height: 25px;
    width: 25px;
    float: left;
    position: fixed;
    top: 90%;
    right: 85%;
    animation: fadeIn 3s;
}

@keyframes fadeIn {

    0% {opacity: 0;}
    25% {opacity: 0.4;}
    75% {opacity: 0.8;}
    100% {opacity: 1;}
     
   }


/* Single column view breakpoint */

@media (max-width: 1000px) {

  .w-2, .w-4, .w-5{
    /* grid-row: span 1; */
    grid-column: span 1;
  }
}


/* Hamburger menu breakpoint */

@media (max-width: 800px) {

  #arrow {
    height: 25px;
    width: 25px;
    float: left;
    position: fixed;
    top: 90%;
    right: 92%;
  
  }

  .App {
    margin-top: 0%;
  }

   nav ul{
     margin-top: 0%;
     transition: all 650ms;
   }

   nav {
     transition: all 650ms;
   }


    .mobileIcon {
      float: left;
      margin-left: 1%;
      display: block;
      position: absolute;
      margin-top: 4%;
    }

    .mobileIcon .burgerIcon{
      height: 40px;
      width: 40px;
      cursor: pointer;
    }

    #mycheckbox:checked + nav {
      height: 305px;
      visibility: visible;
    }

    #mycheckbox:checked + nav ul {
      top: 0px
    }


   .fullNav { 
      position: static;
      padding: 0;
      width: 25%;
      text-align: center;
      margin-top: 3%;
   }

   h1 {
     width: 90%;
     float: left;
     
   }

  .fullNav a {
     display: none;
   }

  .container {
     margin: 0% auto; 
     display: block;
   }

   .fullNav{
     padding-left: 0;
   }

   svg{
     display: inline-block;
   }

}
/* iphone view */

@media (max-width: 376px) {

  .container {
     width: 95%;
     padding: 0;
     display: block;
     margin: auto;
   }

   .fullNav{
     padding-left: 0;
     padding-right:7%;
   }

   #arrow{
     position: fixed;
     right: 45%;
     bottom: 20%;
     height: 40px;
     width: 40px;
     
   }

}
